import React, {useState, useEffect, Component} from 'react';
import '../styles/workpage.css'


class Workpage extends Component {
    render() {
        return (
            <div className="fullPage">
                <p>Workpage!</p>
            </div>
        );
    }
}

export default Workpage;